import React from 'react';

import AugustOutline from '../../../../assets/images/Logo_Outline_v2.svg';

const AugustOutlineContainer = () => (
  <div className="community--identity">
    <AugustOutline className="community--identity-svg" />
    <AugustOutline className="community--identity-svg" />
    <AugustOutline className="community--identity-svg" />
    <AugustOutline className="community--identity-svg" />
    <AugustOutline className="community--identity-svg " />
  </div>
);

export default AugustOutlineContainer;
