/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui';

import GreyCircle from '../../../assets/images/circle.svg';

const ScrollingBanner = () => (
  <Flex
    sx={{
      position: 'relative',
      backgroundColor: 'alizarinCrimson',
      height: ['30px', '30px', '41px'],
      width: '100%',
      overflow: 'hidden',
      alignItems: 'center',
      '*.community-banner': {
        top: 0,
        bottom: 'auto',
        zIndex: 4,
      },
    }}
  >
    <Box sx={{ position: 'relative', whiteSpace: 'nowrap' }}>
      <Box
        sx={{
          margin: '0 auto',
          display: 'inline-block',
          animation: 'marquee 80s linear infinite',
          span: {
            color: 'chatelle',
            fontWeight: 300,
            fontSize: ['14px', '14px', '18px'],
            lineHeight: ['2', '2', null],
            display: ['inline-flex', 'inline-flex', null],
            alignItems: ['center', 'center', null],
          },
          svg: {
            marginTop: '-3px',
            margin: '0 7px',
            marginRight: ['6px', '6px', null],
            transform: ['scale(0.7)', 'scale(0.7)', null],
          },
        }}
      >
        <span>
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
        </span>
      </Box>
      <Box
        sx={{
          display: 'inline-block',
          animation: 'marquee2 80s linear infinite',
          animationDelay: '-40s',
          span: {
            color: 'chatelle',
            fontWeight: 300,
            fontSize: ['14px', '14px', '18px'],
            lineHeight: ['2', '2', null],
            display: ['inline-flex', 'inline-flex', null],
            alignItems: ['center', 'center', null],
          },
          svg: {
            marginTop: '-3px',
            margin: '0 7px',
            marginRight: ['6px', '6px', null],
            transform: ['scale(0.7)', 'scale(0.7)', null],
          },
        }}
      >
        <span>
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
          <GreyCircle />
          DIGNIFIED PERIODS
          <GreyCircle /> DIGNIFIED PERIODS
        </span>
      </Box>
    </Box>
  </Flex>
);

export default ScrollingBanner;
