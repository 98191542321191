/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { Container, Flex, jsx } from 'theme-ui';
import Img from 'gatsby-image';

const Hero = ({ data }) => {
  const { heroes, heroBackground } = data;

  const heroImages = heroes.nodes.map((hero) => hero.fluid);
  const [activeHeroIndex, setActiveHeroIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeHeroIndex === heroImages.length - 1) {
        setActiveHeroIndex(() => 0);
      } else {
        setActiveHeroIndex((prevIndex) => prevIndex + 1);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [activeHeroIndex, heroImages.length]);

  return (
    <Container
      variant="fullwidth"
      id="section-one"
      sx={{
        backgroundImage: `url(${heroBackground.fluid.srcWebp})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      <Flex
        className="hero-images-wrapper"
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '& > .gatsby-image-wrapper': {
            width: ['321px', '525px', '400px', '517px'],
            height: ['405px', '700px', '200px', '650px'],
          },
          '& > picture': {
            position: 'relative',
            zIndex: 1,
            '& > svg': {
              width: ['233px', '350px', '350px', '420px', '600px'],
              height: ['70px', '115px', '115px', '125px', '600px'],
            },
            '& > img': {
              width: ['233px', '350px', '350px', '420px', '600px'],
              height: ['70px', '115px', '115px', '125px', '600px'],
            },
            '&:first-of-type': {
              marginBottom: ['-45px', '-60px'],
            },
            '&:last-of-type': {
              marginTop: ['-35px', '-60px'],
            },
          },
        }}
      >
        <picture>
          <source type="image/svg+xml" srcSet="/images/Logo-Crimson.svg" />
          <img src="/images/Logo-Crimson.png" alt="crimson august logo" />
        </picture>
        <Img
          fluid={heroImages[activeHeroIndex]}
          alt={heroImages[activeHeroIndex].name}
        />
        <picture>
          <source type="image/svg+xml" srcSet="/images/Logo-Grey.svg" />
          <img src="/images/Logo-Grey.png" alt="grey august logo" />
        </picture>
      </Flex>
    </Container>
  );
};

export default Hero;
