/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout';
import SplashPage from '~/components/Splash';
import Metadata from '~/components/Metadata';

const Splash = ({
  data: {
    heroes,
    heroBackground,
    augustIsBackground,
    augustIsBackgroundMobile,
    merchBg,
    merchBgMobile,
    merchImageLayoutDesktop,
    merchImageLayoutMobile,
    communityBackground,
    communityImagesDesktop,
    communityImagesMobile,
  },
}) => (
  <Layout>
    <Metadata
      title="A lifestyle brand working to reimagine periods"
      description="A lifestyle brand working to reimagine periods"
    />
    <SplashPage
      heroes={heroes}
      heroBackground={heroBackground}
      augustIsBackground={augustIsBackground}
      augustIsBackgroundMobile={augustIsBackgroundMobile}
      merchBg={merchBg}
      merchBgMobile={merchBgMobile}
      merchImageLayoutDesktop={merchImageLayoutDesktop}
      merchImageLayoutMobile={merchImageLayoutMobile}
      communityBackground={communityBackground}
      communityImagesDesktop={communityImagesDesktop}
      communityImagesMobile={communityImagesMobile}
    />
  </Layout>
);

export default Splash;

export const query = graphql`
  query SplashPage {
    heroes: allContentfulAsset(
      filter: { title: { regex: "/^Splash-Hero-/" } }
    ) {
      nodes {
        fluid(maxWidth: 750) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    heroBackground: contentfulAsset(title: { eq: "Section-One-Background" }) {
      title
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    augustIsBackground: contentfulAsset(
      title: { eq: "Splash-AugustIs-Background-HiRes" }
    ) {
      fluid(maxWidth: 1600, quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    augustIsBackgroundMobile: contentfulAsset(
      title: { eq: "Splash-AugustIs-Background-Mobile" }
    ) {
      fluid(maxWidth: 375, quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    merchBg: contentfulAsset(title: { eq: "Merch-Background" }) {
      title
      fluid(maxWidth: 1000) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    merchBgMobile: contentfulAsset(title: { eq: "merchbg-mobile" }) {
      title
      fluid(maxWidth: 375) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    merchImageLayoutDesktop: contentfulAsset(
      title: { eq: "image-layout-desktop" }
    ) {
      title
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    merchImageLayoutMobile: contentfulAsset(
      title: { eq: "image-layout-mobile" }
    ) {
      title
      fluid(maxWidth: 375) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    communityBackground: contentfulAsset(
      title: { eq: "Inner-Cycle-Background" }
    ) {
      title
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    communityImagesDesktop: allContentfulCommunityImage(
      sort: { fields: desktopOrder }
    ) {
      nodes {
        desktopOrder
        name
        instagramHandle
        description {
          description
        }
        image {
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    communityImagesMobile: allContentfulCommunityImage(
      sort: { fields: mobileOrder }
    ) {
      nodes {
        mobileOrder
        name
        instagramHandle
        description {
          description
        }
        image {
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
