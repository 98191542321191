import React, { useState } from 'react';
import { analytics } from '@arfabrands/gatsby-theme-assembly';

import CtaArrowDesktop from '~/assets/images/icons/submit-arrow-desktop.svg';
import CtaArrowMobile from '~/assets/images/icons/submit-arrow-mobile.svg';

const InnerCycleForm = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { trackEmailCaptured } = analytics;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email,
      placement: {
        page: 'landing',
        component: 'inner-cycle-form',
        website: window.location.hostname,
      },
    };
    setSubmitting(true);

    try {
      await trackEmailCaptured(payload);
      await fetch('/.netlify/functions/captureEmail', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      setSubmitted(true);
    } catch (err) {
      console.log(err);
    }
    setSubmitting(false);
  };

  return (
    <div className="community--inner-cycle-form">
      <div className="community--inner-cycle-form--wrapper">
        {submitted ? (
          <div className="community--inner-cycle-form--submitted-message">
            <p>THANK YOU - WE WILL BE IN TOUCH</p>
          </div>
        ) : (
          <form
            disabled={submitting}
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <input
              disabled={submitting}
              required
              type="email"
              name="email"
              placeholder="YOUR EMAIL"
              onChange={(e) => setEmail(e.target.value.toLocaleUpperCase())}
              value={email}
            />
            <button type="submit">
              <span className="desktop-only">SUBMIT</span>
              <CtaArrowDesktop className="arrow-desktop" />
              <CtaArrowMobile className="arrow-mobile" />
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default InnerCycleForm;
