import React, { useEffect, useRef, useState } from 'react';
import Img from 'gatsby-image';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import RoundLogo from '~/assets/images/logos/round-logo.svg';
import AugustLogo from '~/assets/images/logos/a-logo.svg';
import AugustOutlineContainer from './AugustOutlineContainer';
import InnerCycleForm from './InnerCycleForm';
import {
  orderedImagesDesktop,
  orderedImagesMobile,
} from '../../../../utils/ordered';
import useWindowDimensions from '~/hooks/components/useWindowDimensions';
import useOnScreen from '~/hooks/components/useOnScreen';

const Community = ({
  data: { communityBackground, communityImagesDesktop, communityImagesMobile },
}) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const { width } = useWindowDimensions();
  const [inBrowser, setInBrowser] = useState();

  useEffect(() => {
    if (typeof window === `undefined`) {
      setInBrowser(false);
    } else {
      setInBrowser(true);
    }
  }, [inBrowser]);

  useEffect(() => {
    if (inBrowser) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.core.globals('ScrollTrigger', ScrollTrigger);
    }

    ScrollTrigger.batch('.community--image', {
      interval: 0.3, // time window (in seconds) for batching to occur. The first callback that occurs (of its type) will start the timer, and when it elapses, any other similar callbacks for other targets will be batched into an array and fed to the callback. Default is 0.1
      batchMax: 2, // maximum batch size (targets)
      onEnter: (batch) =>
        gsap.to(batch, {
          autoAlpha: 1,
          y: 0,
          duration: 0.75,
          stagger: 0.15,
        }),
      onLeave: (batch) =>
        gsap.set(batch, { autoAlpha: 0, y: -125, overwrite: true }),
      onEnterBack: (batch) =>
        gsap.to(batch, {
          autoAlpha: 1,
          y: 0,
          duration: 0.75,
          stagger: 0.15,
        }),
      onLeaveBack: (batch) =>
        gsap.set(batch, { autoAlpha: 0, y: 125, overwrite: true }),
    });

    gsap.from('.community--identity', {
      scrollTrigger: '.community--identity',
      autoAlpha: 0,
      yPercent: 25,
      stagger: 0.25,
    });
  }, [isVisible, inBrowser]);

  const orderedDesktop = orderedImagesDesktop(communityImagesDesktop.nodes);
  const orderedMobile = orderedImagesMobile(communityImagesMobile.nodes);
  if (!inBrowser) {
    return <div ref={ref} />;
  }
  return (
    <div
      id="community"
      style={{ backgroundImage: `url(${communityBackground.fluid.srcWebp})` }}
      ref={ref}
    >
      <div className="community--text-container">
        <div className="community--text-container--text">
          <p>meet our community</p>
          <p>#innercycle</p>
          <p>#onmyperiod</p>
          <div className="community--text-container-svg">
            <RoundLogo className="rotating-logo" />
            <AugustLogo />
          </div>
        </div>
      </div>
      {width >= 1200 &&
        orderedDesktop.map((image, i) => (
          <div
            className={`community--image desktop-${i}`}
            key={`${image.image.instagramHandle}-desktop`}
          >
            <Img fluid={image.image.image.fluid} alt={image.image.name} />
            <div className="flavor-text">
              {image.image.name ? <p>{image.image.name}</p> : <p>August</p>}
              {image.image.instagramHandle ? (
                <p>{image.image.instagramHandle}</p>
              ) : (
                <p>@itsaugust</p>
              )}
              {image.image.description.description && (
                <p>{image.image.description.description}</p>
              )}
            </div>
          </div>
        ))}
      {width >= 900 &&
        width < 1200 &&
        orderedDesktop.map((image, i) => (
          <div
            className={`community--image tablet-landscape-${i}`}
            key={`${image.image.instagramHandle}-tablet-landscape`}
          >
            <Img fluid={image.image.image.fluid} alt={image.image.name} />
            <div className="flavor-text">
              {image.image.name ? <p>{image.image.name}</p> : <p>August</p>}
              {image.image.instagramHandle ? (
                <p>{image.image.instagramHandle}</p>
              ) : (
                <p>@itsaugust</p>
              )}
              {image.image.description.description && (
                <p>{image.image.description.description}</p>
              )}
            </div>
          </div>
        ))}
      {width > 600 &&
        width < 900 &&
        orderedMobile.map((image, i) => (
          <div
            className={`community--image tablet-portrait-${i}`}
            key={`${image.image.instagramHandle}-tablet-portrait`}
          >
            <Img fluid={image.image.image.fluid} alt={image.image.name} />
            <div className="flavor-text">
              {image.image.name ? <p>{image.image.name}</p> : <p>August</p>}
              {image.image.instagramHandle ? (
                <p>{image.image.instagramHandle}</p>
              ) : (
                <p>@itsaugust</p>
              )}
            </div>
          </div>
        ))}
      {width < 600 &&
        orderedMobile.map((image, i) => (
          <div
            className={`community--image mobile-${i}`}
            key={`${image.image.instagramHandle}-mobile`}
          >
            <Img fluid={image.image.image.fluid} alt={image.image.name} />
            <div className="flavor-text">
              {image.image.name ? <p>{image.image.name}</p> : <p>August</p>}
              {image.image.instagramHandle ? (
                <p>{image.image.instagramHandle}</p>
              ) : (
                <p>@itsaugust</p>
              )}
            </div>
          </div>
        ))}
      <AugustOutlineContainer />
      <InnerCycleForm />
    </div>
  );
};

export default Community;
