/** @jsx jsx */
import { useEffect, useRef } from 'react';
import { Box, Link, Flex, Text, jsx } from 'theme-ui';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import CtaArrowDesktop from '~/assets/images/icons/cta-arrow-desktop.svg';
import CtaArrowMobile from '~/assets/images/icons/cta-arrow-mobile.svg';
import CtaArrowDesktopRed from '~/assets/images/icons/cta-arrow-desktop-red.svg';
import CtaArrowMobileRed from '~/assets/images/icons/cta-arrow-mobile-red.svg';

const AugustIs = ({
  data: { augustIsBackground, augustIsBackgroundMobile },
}) => {
  const copyOne = useRef();
  const copyTwo = useRef();
  const copyThree = useRef();
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.core.globals('ScrollTrigger', ScrollTrigger);
    }

    const tl = gsap.timeline({ repeat: -1 });
    tl.to(copyOne.current, {
      opacity: 1,
      yPercent: -25,
      duration: 1.15,
      onComplete: () => {
        tl.pause();
        setTimeout(() => {
          tl.play();
        }, 1000);
      },
    })
      .to(copyOne.current, {
        opacity: 0,
        yPercent: -50,
        duration: 1.15,
      })
      .to(copyTwo.current, {
        opacity: 1,
        yPercent: -25,
        duration: 1.15,
        onComplete: () => {
          tl.pause();
          setTimeout(() => {
            tl.play();
          }, 1000);
        },
      })
      .to(copyTwo.current, {
        opacity: 0,
        yPercent: -50,
        duration: 1.15,
      })
      .to(copyThree.current, {
        opacity: 1,
        yPercent: -25,
        duration: 1.15,
        onComplete: () => {
          tl.pause();
          setTimeout(() => {
            tl.play();
          }, 1000);
        },
      })
      .to(copyThree.current, {
        opacity: 0,
        yPercent: -50,
        duration: 1.15,
      });
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        height: ['800px', '100vh'],
        backgroundImage: [
          `url(${augustIsBackgroundMobile.fluid.srcWebp})`,
          `url(${augustIsBackground.fluid.srcWebp})`,
          `url(${augustIsBackground.fluid.srcWebp})`,
        ],
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: ['right', '75%', null, '50%'],
        backgroundPositionY: [null, null, null, '0%', '50%'],
        '& > div:not(:last-of-type)': {
          position: 'absolute',
          color: 'alizarinCrimson',
          fontWeight: 800,
          textTransform: 'uppercase',
          opacity: 0,
          margin: 0,
          transform: 'translateY(25%)',
          top: ['217px', '180px', '149px', '120px', '195px'],
          left: ['55px', '100px', null, '112px', '133px'],
          '& > div': {
            margin: 0,
            padding: 0,
            fontSize: ['26px', '48px', null, '54px', '72px'],
            lineHeight: ['31.2px', '57.6px', null, '64.8px', '72px'],
            letterSpacing: '-1%',
          },
        },
      }}
    >
      <Box ref={copyOne}>
        <Text>August.</Text>
        <Text>a lifestyle</Text>
        <Text>brand working</Text>
        <Text>to reimagine</Text>
        <Text>periods</Text>
      </Box>
      <Box ref={copyTwo}>
        <Text>everything we do</Text>
        <Text>is with, by,</Text>
        <Text>and for our</Text>
        <Text>community, our</Text>
        <Text>#innercycle</Text>
      </Box>
      <Box ref={copyThree}>
        <Text>eliminating</Text>
        <Text>period stigma</Text>
        <Text>and democratizing</Text>
        <Text>access to</Text>
        <Text>period care.</Text>
      </Box>

      <Box
        className="august-is--cta"
        sx={{
          position: 'absolute',
          margin: 0,
          padding: 0,
          left: ['55px', '100px', '100px', '109px', '195px'],
          top: ['575px', '850px', '550px', 'auto', null],
          bottom: [null, null, null, '146px', null],
          height: ['43px', '49px', null, null, '70px'],
          width: ['201px', '269px', null, null, 'auto'],
        }}
      >
        <Link
          href="https://links.genevachat.com/invite/0f355b9e-b373-43ac-a8df-b843b3d6c433"
          target="_blank"
          rel="noreferrer"
          sx={{
            textTransform: 'uppercase',
            padding: ['6px', '6px 12px', null, null, '12px 18px'],
            width: '100%',
            fontWeight: 300,
            fontSize: ['28px', '34px', null, null, '36px'],
            lineHeight: ['31px', '37.43px', null, null, '36px'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'alizarinCrimson',
            color: 'canary',
            outline: 'none',
            webkitAppearance: 'none',
            appearance: 'none',
            border: 0,
            letterSpacing: '1%',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
          }}
        >
          <Text>Welcome</Text>
          <CtaArrowDesktop className="august-is--cta--arrow-desktop" />
          <CtaArrowMobile className="august-is--cta--arrow-mobile" />
          <CtaArrowDesktopRed className="august-is--cta--arrow-desktop august-is--cta--arrow-desktop--red" />
          <CtaArrowMobileRed className="august-is--cta--arrow-mobile august-is--cta--arrow-mobile--red" />
        </Link>
      </Box>
    </Box>
  );
};

export default AugustIs;
