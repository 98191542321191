import React, { useEffect, useState } from 'react';

import Hero from '~/components/Splash/Sections/Hero';
import AugustIs from '~/components/Splash/Sections/AugustIs';
// import Merch from '~/components/Splash/Sections/Merch';
import Community from '~/components/Splash/Sections/Community';
import ScrollingBanner from '~/components/Splash/Banner';

const Splash = ({
  heroes,
  heroBackground,
  augustIsBackground,
  augustIsBackgroundMobile,
  // merchBg,
  // merchBgMobile,
  // merchImageLayoutDesktop,
  // merchImageLayoutMobile,
  communityBackground,
  communityImagesDesktop,
  communityImagesMobile,
}) => {
  const heroImages = heroes.nodes.map((hero) => hero.fluid);

  const [activeHeroIndex, setActiveHeroIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeHeroIndex === heroImages.length - 1) {
        setActiveHeroIndex(() => 0);
      } else {
        setActiveHeroIndex((prevIndex) => prevIndex + 1);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [activeHeroIndex, heroImages.length]);

  return (
    <>
      <Hero
        data={{
          heroes,
          heroBackground,
        }}
      />
      <ScrollingBanner />
      <AugustIs data={{ augustIsBackground, augustIsBackgroundMobile }} />
      <ScrollingBanner />
      {/* <Merch
        data={{
          merchBg,
          merchBgMobile,
          merchImageLayoutDesktop,
          merchImageLayoutMobile,
        }}
      /> */}
      <Community
        data={{
          communityBackground,
          communityImagesDesktop,
          communityImagesMobile,
        }}
      />
    </>
  );
};

export default Splash;
