export const orderedImagesDesktop = (images) => [
  {
    // bed
    image: images[0],
    top: '80px',
    left: '202px',
    height: '199px',
    width: '283px',
  },
  {
    // hand
    image: images[1],
    top: '0px',
    right: '143px',
    height: '374px',
    width: '280px',
  },
  {
    // jeans
    image: images[2],
    top: '645px',
    left: '42px',
    height: '179px',
    width: '384px',
  },
  {
    // red
    image: images[3],
    top: '539px',
    left: '485px',
    height: '581px',
    width: '432px',
  },
  {
    // trampoline
    image: images[4],
    top: '469px',
    right: '0px',
    height: '292px',
    width: '219px',
  },
  {
    // cat
    image: images[5],
    top: '940px',
    right: '0px',
    height: '377px',
    width: '283px',
  },
  {
    // cowboy
    image: images[6],
    top: '975px',
    left: '100px',
    height: '362px',
    width: '242px',
  },
  {
    // sunny
    image: images[7],
    top: '1544px',
    left: '179px',
    height: '375px',
    width: '282px',
  },
  {
    // girl power
    image: images[8],
    top: '1380px',
    right: '243px',
    height: '261px',
    width: '384px',
  },
  {
    // pose
    image: images[9],
    top: '2045px',
    left: '100px',
    height: '609px',
    width: '487px',
  },
  {
    // flex
    image: images[10],
    top: '1945px',
    right: '0px',
    height: '260px',
    width: '346px',
  },
  {
    // square
    image: images[11],
    top: '2319px',
    right: '243px',
    height: '307px',
    width: '307px',
  },
];

export const orderedImagesMobile = (images) => [
  {
    image: images[0],
    top: '53px',
    left: '40px',
    height: '120px',
    width: '170px',
  },
  {
    image: images[1],
    top: '82px',
    right: '0px',
    height: '179px',
    width: '134px',
  },
  {
    image: images[2],
    top: '393px',
    left: '40px',
    height: '168px',
    width: '126px',
  },
  {
    image: images[3],
    top: '434px',
    right: '0px',
    height: '225px',
    width: '167px',
  },
  {
    image: images[4],
    top: '730px',
    left: '40px',
    height: '107px',
    width: '228px',
  },
  {
    image: images[5],
    top: '933px',
    left: '40px',
    height: '239px',
    width: '160px',
  },
  {
    image: images[6],
    top: '904px',
    right: '0px',
    height: '108px',
    width: '144px',
  },
  {
    image: images[8],
    top: '1247px',
    left: '40px',
    height: '168px',
    width: '126px',
  },
  {
    image: images[7],
    top: '1143px',
    right: '0px',
    height: '158px',
    width: '126px',
  },
  {
    image: images[9],
    top: '1466px',
    left: '67px',
    height: '258px',
    width: '194px',
  },
  {
    image: images[10],
    top: '1749px',
    right: '0px',
    height: '136px',
    width: '200px',
  },
  {
    image: images[11],
    top: '1926px',
    left: '53px',
    height: '194px',
    width: '194px',
  },
];
export const orderedImagesTablet = (images) => [
  {
    // bed
    image: images[0],
    top: '53px',
    left: '40px',
    height: '139px',
    width: '198px',
  },
  {
    // hand
    image: images[1],
    top: '82px',
    right: '0px',
    height: '262px',
    width: '196px',
  },
  {
    // trampoline
    image: images[2],
    top: '393px',
    left: '40px',
    height: '204px',
    width: '153px',
  },
  {
    // red
    image: images[3],
    top: '434px',
    right: '0px',
    height: '406px',
    width: '302px',
  },
  {
    // jeans
    image: images[4],
    top: '730px',
    left: '40px',
    height: '125px',
    width: '269px',
  },
  {
    // cowboy
    image: images[5],
    top: '933px',
    left: '40px',
    height: '253px',
    width: '169px',
  },
  {
    // flex
    image: images[6],
    top: '904px',
    right: '0px',
    height: '182px',
    width: '242px',
  },
  {
    // cat
    image: images[8],
    top: '1247px',
    left: '40px',
    height: '264px',
    width: '198px',
  },
  {
    // pose
    image: images[7],
    top: '1143px',
    right: '0px',
    height: '426px',
    width: '341px',
  },
  {
    // sunny
    image: images[9],
    top: '1566px',
    left: '67px',
    height: '262px',
    width: '197px',
  },
  {
    // girl power
    image: images[10],
    top: '1749px',
    right: '0px',
    height: '183px',
    width: '269px',
  },
  {
    // square
    image: images[11],
    top: '1926px',
    left: '53px',
    height: '215px',
    width: '215px',
  },
];
